<template>
    <div class="e-search-input" :class="`size-${size}`">
        <b-input v-model="search" :id="id" :name="name" :disabled="disabled" :form="form"
                 :autofocus="autofocus" :size="size" :readonly="readonly"
                 :plaintext="plaintext" autocomplete="off" :placeholder="placeholder|tr" :formatter="formatter"
                 :trim="trim" :lazy-formatter="lazyFormatter" :number="number" :lazy="lazy" :debounce="debounce"
                 :maxlength="maxLengthComputed" ref="input"
                 type="text" :no-wheel="noWheel" :list="list"
                 @keypress="onKeyPressed" @input="onInput" @change="onChange"
                 @click="$emit('click', $event)"
                 @update="$emit('update', $event)" @blur="$emit('blur', $event)"
                 @focusin="$emit('focusin', $event)" @focusout="$emit('focusout', $event)" :data-cy="dataCy">
        </b-input>
        <i class="search-icon fas fa-search"></i>
        <i v-if="!isStringEmpty(value)" @click="clear" class="clear-icon fas fa-close"/>
    </div>
</template>

<script>
export default {
    name: `e-search-input`,
    props: {
        id: {type: String},
        name: {type: String},
        disabled: {type: [Boolean, String]},
        form: {type: String},
        autofocus: {type: Boolean, default: false},
        size: {type: String, default: `md`},
        value: {type: [String, Number], default: ``},
        readonly: {type: Boolean, default: false},
        plaintext: {type: Boolean, default: false},
        minlength: {type: [String, Number]},
        maxlength: {type: [String, Number]},
        placeholder: {type: String, default: `Search|Rechercher`},
        formatter: {type: Function},
        lazyFormatter: {type: Boolean},
        trim: {type: Boolean, default: true},
        number: {type: Boolean, default: false},
        lazy: {type: Boolean},
        debounce: {type: [Number, String], default: 500},
        type: {type: String, default: `text`},
        noWheel: {type: Boolean, default: false},
        list: {type: String},
        debounceTime: {type: Number, default: 500},
        dataCy: {type: String}
    },
    data() {
        return {
            timeout: null,
            isStringEmpty
        }
    },
    computed: {
        search: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit(`input`, value);
            }
        },
        maxLengthComputed() {
            return this.maxlength ? this.maxlength : 50;
        }
    },
    methods: {
        clear() {
            this.$emit(`change`, ``);
            this.$emit(`clear`);
            this.search = ``;
        },
        onInput(value) {
            if (this.type !== `number`)
                this.$emit(`input`, value);
        },
        onChange(value) {
            let newValue = value;

            this.$emit(`change`, newValue);
            this.search = newValue;
        },
        onKeyPressed(event) {
            this.$emit(`keypress`, event);
            if (event.keyCode === 13) {
                this.$emit(`enter`, event);
            }
        },
        focus() {
            this.$refs.input.focus();
        },
        select() {
            this.$refs.input.select();
        }
    },
    watch: {
        value() {
            if (this.timeout)
                clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit(`debounce`, this.value);
            }, this.debounceTime);
        }
    }
}
</script>

<style lang="scss" scoped>
.e-search-input {
    position: relative;
    color: #333;

    .form-control {
        padding-left: 30px;
        padding-right: 30px;
    }

    .clear-icon,
    .search-icon {
        position: absolute;
        width: 28px;
        top: 3px;
        height: calc(100% - 6px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
    }

    .search-icon {
        left: 3px;
        opacity: 0.5;
    }

    .clear-icon {
        right: 3px;
        opacity: 0.75;

        &:hover {
            background: #eee;
            opacity: 1;
        }
    }
}
</style>
